import React from "react";
import ReactDOM from "react-dom/client";
import "@radix-ui/themes/styles.css";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Theme } from "@radix-ui/themes";
import { AppLayout, AuthLayout, OnboardingLayout } from "./layouts";
import "./index.css";
import { GoPasswordlessContextProvider } from "@gopasswordless/sdk";
import {
  ApiKeys,
  BillingPage,
  CompleteSignupPage,
  ContentDetails,
  ContentDrafts,
  ContentIdeas,
  ContentPublished,
  ContentSchedule,
  DomainsPage,
  Integrations,
  LoginPage,
  OnboardingBrandPage,
  OnboardingContentPage,
  OnboardingSchedulePage,
  OnboardingSuccessPage,
  OtpPage,
  SignupPage,
  TeamMembersPage,
} from "./pages";
import "./globals.css";
import {
  AuthProvider,
  BrandProvider,
  SentryProvider,
  ToastsProvider,
} from "./contexts";
import { LinkedinCallback, XCallback } from "./pages/app/callbacks";
import { OnboardingProvider } from "./contexts/onboarding";
import { NotificationsProvider } from "./contexts/notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { store } from "./redux";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <BrandProvider>
        <NotificationsProvider>
          <AppLayout />
        </NotificationsProvider>
      </BrandProvider>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/content/schedule" />,
      },
      {
        path: "content/schedule",
        element: <ContentSchedule />,
      },
      {
        path: "content/ideas",
        element: <ContentIdeas />,
      },
      {
        path: "content/drafts",
        element: <ContentDrafts />,
      },
      {
        path: "content/published",
        element: <ContentPublished />,
      },
      {
        path: "brands/:brandId/content/:contentId",
        element: <ContentDetails />,
      },
      {
        path: "api-keys",
        element: <ApiKeys />,
      },
      {
        path: "integrations",
        element: <Integrations />,
      },
      {
        path: "team-members",
        element: <TeamMembersPage />,
      },
      {
        path: "callbacks/x",
        element: <XCallback />,
      },
      {
        path: "callbacks/linkedin",
        element: <LinkedinCallback />,
      },
      {
        path: "settings",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Navigate to="/settings/billing" />,
          },
          {
            path: "billing",
            element: <BillingPage />,
          },
          {
            path: "domains",
            element: <DomainsPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/onboarding",
    element: (
      <OnboardingProvider>
        <OnboardingLayout />
      </OnboardingProvider>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/onboarding/brand" />,
      },
      {
        path: "brand",
        element: <OnboardingBrandPage />,
      },
      {
        path: "schedule",
        element: <OnboardingSchedulePage />,
      },
      {
        path: "content",
        element: <OnboardingContentPage />,
      },
      {
        path: "success",
        element: <OnboardingSuccessPage />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "signup",
        element: <SignupPage />,
      },
      {
        path: "otp",
        element: <OtpPage />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "complete",
        element: <CompleteSignupPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Theme>
      <ToastsProvider>
        <SentryProvider>
          <GoPasswordlessContextProvider
            settings={{
              appId: `${import.meta.env.VITE_GO_PASSWORDLESS_APP_ID}`,
              appName: "MarketerAI Dev",
              appLogo: "/logo.svg",
              primaryColour: "#000000",
              theme: "light",
              privacyUrl: "https://getmarketerai.com/privacy",
              termsUrl: "https://getmarketerai.com/terms",
            }}
          >
            <Provider store={store}>
              <AuthProvider>
                <GoogleOAuthProvider
                  clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
                >
                  <RouterProvider router={router} />
                </GoogleOAuthProvider>
              </AuthProvider>
            </Provider>
          </GoPasswordlessContextProvider>
        </SentryProvider>
      </ToastsProvider>
    </Theme>
  </React.StrictMode>
);
